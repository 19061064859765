.add-member-page {
    width: 100%;
  }

  .upload-documents-boder {
    width: 90%;
    height: 160px;
    border-style: dashed;

  }

  .upload-documents-btn {
    background-color: #2d4b78;
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
  }

  .my-submit-btn {
    background-color: #192e4d;
    border-radius: 5px;
    padding: 10px 50px;
    font-weight: bold;
    color: #fff;

  }

  .uploader {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }
  .uploader label {
    float: left;
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 7px;
    border: 3px solid #eee;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .uploader label:hover {
    border-color: #454cad;
  }
  .uploader label.hover {
    border: 3px solid #454cad;
    box-shadow: inset 0 0 0 6px #eee;
  }
  .uploader label.hover #start i.fa {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.3;
  }
  .uploader #start {
    float: left;
    clear: both;
    width: 100%;
  }
  .uploader #start.hidden {
    display: none;
  }
  .uploader #start i.fa {
    font-size: 50px;
    margin-bottom: 1rem;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  .uploader #response {
    float: left;
    clear: both;
    width: 100%;
  }
  .uploader #response.hidden {
    display: none;
  }
  .uploader #response #messages {
    margin-bottom: .5rem;
  }
  .uploader #file-image {
    display: inline;
    margin: 0 auto .5rem auto;
    width: auto;
    height: auto;
    max-width: 180px;
  }
  .uploader #file-image.hidden {
    display: none;
  }
  .uploader #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;
    color: #f00;
  }
  .uploader #notimage.hidden {
    display: none;
  }
  .uploader progress,
  .uploader .progress {
    display: inline;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background-color: #eee;
    overflow: hidden;
  }
  .uploader .progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: #eee;
  }
  .uploader .progress[value]::-webkit-progress-value {
    background: -webkit-gradient(linear, left top, right top, from(#393f90), color-stop(50%, #454cad));
    background: linear-gradient(to right, #393f90 0%, #454cad 50%);
    border-radius: 4px;
  }
  .uploader .progress[value]::-moz-progress-bar {
    background: linear-gradient(to right, #393f90 0%, #454cad 50%);
    border-radius: 4px;
  }
  .uploader input[type="file"] {
    display: none;
  }
  .uploader div {
    margin: 0 0 .5rem 0;
    color: #5f6982;
  }
  .uploader .btn {
    display: inline-block;
    margin: .5rem .5rem 1rem .5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    border: none;
    border-radius: .2rem;
    outline: none;
    padding: 0 1rem;
    height: 36px;
    line-height: 36px;
    color: #fff;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    background: #454cad;
    border-color: #454cad;
    cursor: pointer;
  }

  #messages strong{
    word-break: break-all;
  }


  /* .form-control.is-invalid, .was-validated #file-drag{
    border-color: #fe544b;
padding-right: calc(1.5em + .94rem);
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fe544b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fe544b' stroke='none'/%3e%3c/svg%3e");
background-repeat: no-repeat;
background-position: right calc(.375em + .235rem) center;
background-size: calc(.75em + .47rem) calc(.75em + .47rem);
  } */