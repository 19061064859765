.logo-lg img {
    width: 67px;
    height: auto;
  }
.nav__badge_2mhYts {
    height: 16px;
    margin-left: 10px;
    padding: 2px 2px 1px;
    font-size: 8px;
    font-weight: 700;
    color: #ff9000;
    text-transform: uppercase;
    border: 1px solid #ff9000;
    border-radius: 2px;
}

.notiheight {
  overflow-y: scroll;
  height: 500px;
  scrollbar-width: thin;
}
/* width */
.notiheight::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.notiheight::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.notiheight::-webkit-scrollbar-thumb {
  background: #1c3150; 
  border-radius: 10px;
}

/* Handle on hover */
.notiheight::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
.notiheight .simplebar-wrapper {
  display: none;
}
.notiheight .simplebar-horizontal {
  display: none;
}
.notiheight .simplebar-vertical {
  display: none;
}

.noti{
  transform: translate(0px, 72px)
}
.dropdown-noti {
  -webkit-box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  -webkit-animation-name: DropDownSlide;
  animation-name: DropDownSlide;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;


  min-width: 350px;
  padding: .5rem 0;
  margin: 0;
  font-size: .9rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0,0,0,.15);
  border-radius: .25rem;


  right: -150px !important;
left: auto !important;
top: 0;
}
.backgroundOverlayNoti{
  background-color:transparent;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display:block;
}
.noti-title {
  font-size: 12px;
  font-weight: bold;
  color: #182d4c;
}