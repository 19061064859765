/* .form-control.is-valid + .tox-tinymce, .was-validated .form-control:valid + .tox-tinymce {
    border: 2px solid #198754 !important;
  } */


  /* .form-control.is-valid + .tox-tinymce, .was-validated .form-control:valid + .tox-tinymce {
    border: 2px solid #198754 !important;
  } */

  /* .form-control.is-valid + .tox-tinymce, .was-validated .form-control:valid + .tox-tinymce{
    border: 2px solid #198754 !important;
  }


  .form-control.is-invalid + .tox-tinymce, .was-validated .form-control:invalid + .tox-tinymce {
    border: 2px solid #dc3545 !important;
  } */

  textarea.form-control {
    display: none;
  }
  .form-control.is-valid + .cke, .was-validated .form-control:valid + .cke {
    border: 1px solid #198754 !important;
  }

  .form-control.is-invalid + .cke, .was-validated .form-control:invalid + .cke {
    border: 1px solid #dc3545 !important;
  }

    /* .form-control.is-valid + .ck.ck-reset.ck-editor.ck-rounded-corners, .was-validated .form-control:valid + .ck.ck-reset.ck-editor.ck-rounded-corners{
    border: 2px solid #198754 !important;
  }


  .form-control.is-invalid + .ck.ck-reset.ck-editor.ck-rounded-corners, .was-validated .form-control:invalid + .ck.ck-reset.ck-editor.ck-rounded-corners {
    border: 2px solid #dc3545 !important;
  } */

  .cke .ck-editor__editable {
    height: 100px !important;
  }

  .cke-no-border .ck-editor__editable {
    height: 100px !important;
  }