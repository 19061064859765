.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.workList {
  display: flex;
}
.userPicture {
  width: 100px;
  height: 100px;
  margin-right: 15px;
}
.userPicture img{
  width: 100px;
  border-radius: 50%;
  position: relative;
  z-index: 9;
  background: #fff;
  border: 2px solid #ddd;
  object-fit: contain;
  object-position: center;
  height: 100px;
}

.commentBox {
  border: 1px solid #c1c1c1;
  padding: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.workList:last-child .commentBox{
  margin-bottom: 0;
}

.commentBox::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  border-left: 2px solid #5db64a;
  left: -62.5px;
  top: 68px;
  z-index: 1;
}
.workList:last-child .commentBox{
  position: static;
}
.userName {
  display: flex;
  justify-content: space-between;
}
.userName {
  display: flex;
  justify-content: space-between;
  background-color: #1c3a64;
  color: #fff;
}
.userName p{
  margin: 1rem;
}
.commentBox hr {
  border-top: 1px solid #b9b9b9;
}
.edite-Client {
  padding: 0;
  list-style: none;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;
}
.edite-Client li{ display: inline-block;}
.edite-Client li {
  display: inline-block;
  margin-right: 15px;
  border-right: 2px solid #1c3a64;
  padding-right: 15px;
}
.edite-Client li:last-child{
  border: none;
}
.commentBox div p {
  word-break: break-all;
}
.caseMassgge{
  position: relative;
  background-color: rgb(25, 46, 77);
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: .25rem .25rem 0 0;
  box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.34);
  z-index: 9;
}
.caseMassgge p {
  margin: 0;
}
/* .caseMassageBox {
  padding-top: 3px;
} */
.caseComment a{
  background-color: rgba(255,255,255,0.2);
  border-radius: 5px;
  padding: 5px;
}
/* -------------------- */
.workLists {
  display: flex;
}


.commentBoxs {
  border: 1px solid #c1c1c1;
  padding: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.workLists:last-child .commentBoxs{
  margin-bottom: 0;
}

.commentBoxs::after {
display: none;
}
.workLists:last-child .commentBoxs{
  position: static;
}
.linkHTML {
  min-height: 80px;
  overflow-y: scroll;
}
/* --------after-new bootstarp ----------------- */
a {
  text-decoration: none;
}
.card-header{
  padding: .875rem 1.75rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #343a40;
  font-family: Inter,sans-serif;
}
.h6, h6 {
  font-size: .9rem;
}
body {
  font-size: 0.9rem;
  font-weight:400;
  line-height: 1.5;
  color: #495057 ;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Design Icons";

}
.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\f0142");
}
label {
  font-weight: 600;
  margin-bottom: .5rem;
}
.table{
  color: #495057;
}
.table > :not(caption) > * > * {
  padding: .75rem .75rem;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}