.masterBox{
    width: 100%;
    padding: 15px;
    margin-top: 15px;
    border: 1px solid #ddd;
}
.masterItem {
    border: 1px solid #ddd;
    padding: 15px;
    margin-top: 15px;
  }
  .iconAction {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    align-items: center;
  }
  .addChipBtn {
    width: 100%;
  }
  .fieldList {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .fieldList li div {
    display: flex;
    padding: 5px;
    align-items: center;
  } 

  .MuiStack-root .MuiButtonBase-root .MuiChip-icon{
    margin-right: 14px !important;
  }
  .addFeild:hover {
    background-color: #192e4d !important;
  }
  .chiplist {
    display: flex;
    flex-wrap: wrap;
  }

  .chiplist div{
    margin-bottom: 5px !important;
    margin-left: 0px !important;
    margin-right: 8px !important;
  }
  .editemaincat{
    width: 80px;
display: inline-flex;
justify-content: space-between;
margin-left: 50px;
  }

  .iconAction div {
    width: 100%;
  }

  /* .MuiChip-root {
    width: 100%;
    margin-bottom: 15px;
  } */