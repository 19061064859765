.border-bottom2 {
  border: 1px solid #d5d5d5 !important;
  text-overflow: ellipsis;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #e8e8e8;
  padding: 5px;
  border-radius: 4px;
  color: #262626;
}
.border-dotted {
  border-bottom: 1px dotted #a6a6a6;
  padding: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #262626;
}

.spin-a {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-w-button a {
  width: 100% !important;
  text-align: center;
}
.finename {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* pdf-wrapper */

/* #pdfWrapper {
    width: 596px;
    height: auto;
  }
  .page1 {
    width: 595px;
    height: 842px;
    background-color: red;
  }
  .page2 {
    width: 595px;
    height: 842px;
    background-color: blue;
  }
  .page3 {
    width: 595px;
    height: 842px;
    background-color: green;
  }
  .page4 {
    width: 595px;
    height: 842px;
    background-color: pink;
  }
   */
.stackholderimage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.create-btn-model {
  background: none;
  border: none;
}

@media (min-width: 250px) and (max-width: 720px) {
  .swirtchcLass {
    display: flex;
    justify-content: space-between;
  }
}
.mipc th {
  vertical-align: middle;
}
.mipc .action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ck-editor__editable {
  height: 300px;
}
.ck.ck-editor__main {
  position: relative;
}
/* .ck.ck-balloon-panel.ck-balloon-panel_arrow_nw.ck-balloon-panel_visible.ck-balloon-panel_with-arrow {
  top: 674px !important;
} */

.client-lable {
  display: flex;
  align-items: center;
}
.client-lable p {
  margin-left: 15px;
  text-align: left;
}

.client-lable img {
  border: 1px solid #ddd;
}

.scrollTbale {
  overflow-y: scroll;
  height: 200px;
  scrollbar-width: thin;
}

/* .scrollTbale::-webkit-scrollbar {
  width: 10px;
} */
.scrollTbale::-webkit-scrollbar {
  height: 12px;
  width: 5px;
  /* background: #000; */
}

.scrollTbale::-webkit-scrollbar-thumb {
  background: #192e4d;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.scrollTbale::-webkit-scrollbar-corner {
  background: #000;
}
#fileList {
  list-style: none;
}
#fileList li {
  display: inline;
  border-right: 2px solid #ddd;
  margin-right: 5px;
  padding-right: 5px;
}
#fileList li:last-child{
  border-right:none
}
.twoBtn {
  display: flex;
  justify-content: space-between;
}
.twoBtn button {
  padding: 11px 5px !important;
}


.ptextOver  {
  display: flex;

  width: 100%;

}
.textOver {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 83%; */
  width: 100%;
  margin-bottom: 0;
}
.dropdown-menu {
  font-size: .9rem;
}
.badge-soft-success {
  color: #34c38f;
}
.badge-soft-success {
  color: #34c38f;
  background-color: rgba(52,195,143,.18);
}
.badge-soft-danger {
  color: #fe544b;
  background-color: rgba(254,84,75,.18);
}