@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

*{
    margin: 0px;
    padding: 0px;
  /* list-style: none; */
}
.main-box-wrapper{
    background-color: #ddd;
    padding: 10px;
    position: relative;
}
.genrate-report {
  position: fixed;
  right: 15px;
  top: 75px;
}
.genrate-report1 {
  position: fixed;
  right: 15px;
  top: 150px;
}
.pdf-genrate{
    width: 595px;
    height: auto;
    margin: 0 auto;
}

.warpper-box{
  width: 585px;
    background-color: #fff;
    height: 831px;
    border: 4px solid #9fc64c;
    margin-bottom: 1px;
    position: relative;
    
}
.bg-waterMark{
  width: 50%;
  height: auto;
  position: absolute;
  object-fit: contain;
  object-position: center;
  margin: 0 auto;
  left: 25%;
  transform: translatey(-50%);
  top: 50%;
}
.warpper-box:last-child{
  margin-bottom: 0;
}

.logo-pdf{
    text-align: end;
}
.wraper-logo img{
    width: 100%;
}
.logo-pdf img{
    height: 220px;
    width: 205px;
}

.main-watermk img{
    height: 450px;
    width: 100%;
}
.main-watermk{
    position: relative;
}

.top-right {
    position: absolute;
  top: 48%;
 right: -110px;
  transform: translate(-50%, -50%);
  }
  .pdf-main-heading{
    text-align: end;
    font-family: Arial !important;
    line-height: 50px;
    font-weight: 500;
    font-size: 35px;
  }
  .text-box{
    background-color: #9fc64c;
    padding: 5px 5px 10px 5px;
    width: 235px;
    position: absolute;
    bottom: 0;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .side-content-box{
    color: #fff;
    font-family: Arial !important;
    line-height: 26px;
    font-size: 16px;
    font-weight: 500;
  }
  .side-content-box a:nth-child(2){
   text-decoration: none;
  }
  .preperd-box{
    position: absolute;
    right: 10px;
    bottom: 0px;
    width: 167px;
  }
  .preperd-text{
    font-size: 14px;
    font-family: Arial !important;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 0;
    color: #fff;
  }
 

  /* -------2section--------- */
  /* .water-marks{
    background-image: url(./img/watermark-new.jpg);
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 550px;
    background-size: contain;

  } */
  .Highlight-text{
    color: red;
    font-family: Arial !important;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  .text-heading{

    padding: 0 15px;
  }
  .text-heading-cx{
  
    padding: 20px;
  }
  .text-h{
    font-family: Arial !important;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    text-align: center;
  }
  .text-h-cx{
    font-family: Arial !important;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    text-align: justify;
  }
  .cover-border{
    position: relative;
    
  }
  .bottom-box img{
    width: 100%;
    position:absolute;
    bottom: 0px;

  }
  .text-ctx{
    position: absolute;
    top: 5px;
    right: 5px;

  }
  .text-ctx h1{
    font-family: Arial !important;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  /* third-page-design */
  .cont-h{
    font-size: 25px;
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    color: #337eb7;
    padding-left: 15px;
    margin-top: 15px;

  }
  .cover-ul{
     padding: 15px;
  }
  .cover-ul ul {
    padding: 0;
  }
  .list-p{
    font-family: Arial !important;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    padding-right: 10px;
    /* line-height: 40px; */
  }
  .list-p-text{
    font-family: Arial !important;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin: 15px;
  }
  .list-p-text-child{
    font-family: Arial !important;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin: 15px;

  }
  /* -----table */
  .report-table th, .report-table td {
    border:1px solid black;
    padding: 2px;
  }

  
  #table2 {
    border-collapse: collapse;
  }
  .pdf-table {
    padding: 0 15px;
  }



  .cust-cx-table{
    background-color: #337eb7;
    color: #fff;
    font-family: Arial !important;
    font-size: 10.3px;
    font-weight: 500;
  }
  .tabl-cx,.ebay{
    font-family: Arial !important;
    font-size: 10.3px;
    font-weight: 500;
  }
 

  .tble-cx{
    font-family: arial;
    font-size: 12px;
    font-weight: 500;
  }
  .table-ul{
    padding-left: 30px;
    margin-bottom: 0;
  }
  .table-ul li {
    list-style: disc;

  }

  .cover-wra-text{
    padding: 0 15px;
  }
.cont-sec{
    font-size: 18px;
    font-family: arial;
    font-weight: 500;
    color: #337eb7;
    margin-top: 15px;
    margin-bottom: 5px;
}
.left-para{
    font-family: Arial !important;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0px;
}
.conjuisted{
    margin: 10px 0px;
}
.text-dangers{
    color: red;
    font-family: Arial !important;
    font-size: 15px;
    font-weight: 500;
    padding-right: 5px;
}

.bottom-module{
    margin-top: 30px;
}
.main-px{
    display: flex;
    align-items: center;
    padding-right: 5px;

}
.text-lefts{
    font-family: Arial !important;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    text-decoration: underline;
    margin-bottom: 10px;
}
.text-rights{
    font-family: Arial !important;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
}
.cover-wra-text-d{
  margin: 0 15px;
  }
.text-dangerd{
    color: red;
    font-family: Arial !important;
    font-size: 15px;
    font-weight: 500;
    padding-right: 5px;
}
.main-px-cs{
    margin: 30px 0px;
}
.tex-cx-d{
    font-family: Arial !important;
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

/* -------table-css */
.tick {
    font-size: 18px;
    color: #499354;
}
.clear {
    font-size: 18px;
    color: #D22C16;
}
.boxer {
  display: table;
  border-collapse: collapse;
  width: 100%;
  border-top: 1px solid #000;
  border-left: 1px solid #000 !important;
  background-color: #fff;
}
.boxer .box-row {
  display: table-row;
  border-left: 1px solid #000;
 
}

.boxer .box-row:first-child {
  font-weight:bold;
}
.boxer .box {
  display: table-cell;
  vertical-align: top;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 5px;
  
}
.word-break{
  word-break: break-all;
}
.box-row:first-child {
  border-left: 1px solid #000 !important;
}
/* .boxer .ebay {
  padding:5px 1.5em;
} */
.boxer .google {
  padding:5px 1.5em;
}
.boxer .amazon {
  padding:5px 1.5em;
}
.center {
  text-align:center;
}
.right {
  float:right;
}
.hide {
    border: 0!important;
    background: none;
}

.tl-width {
  width: 44%;
}

.greenL {
  background-color: #337eb7;
  color: #fff;
  text-align: center;
  padding-bottom: 5px;
  font-weight: bold;
}
.lastTable {
  padding-left: 15px;
  margin-bottom: 5px;
}
.confi {
  font-family: Arial !important;
  font-size: 10.3px;
  font-weight: 500;
  margin-top: 10px;
}
.confi,
.list-p-text,
.list-p-text-child,
.box.ebay,
#civilLitigationInvestigation p,
#criminalRecordInvestigation p,
#bankruptcyInvestigation p,
#corporateInterestsInvestigation p,
#worldComplianceInvestigation p,
#mediaandInternetSearches p,
#investigatorReport p,
#riskManagementReview p,
#blacklistInvestigation p {
  text-align: justify;
}

.dotted-line {
  display: flex;
  white-space: nowrap;
}
.dotted-line div {
  width: 100%;
border-bottom: 2px dotted #000;
height: 10px;
margin: 0 6px;
}