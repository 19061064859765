.vertical-collpsed .vertical-menu #sidebar-menu .GbBBzF a {
    width: 40px !important;
    min-width: 40px !important;
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: unset;
  }

.vertical-collpsed .vertical-menu #sidebar-menu  .GbBBzF a span

{
  display: none;
}